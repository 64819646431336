<template>
  <svg viewBox="0 0 24 25">
    <path
      d="M3 3.5H21C21.2652 3.5 21.5196 3.60536 21.7071 3.79289C21.8946 3.98043 22 4.23478 22 4.5V20.5C22 20.7652 21.8946 21.0196 21.7071 21.2071C21.5196 21.3946 21.2652 21.5 21 21.5H3C2.73478 21.5 2.48043 21.3946 2.29289 21.2071C2.10536 21.0196 2 20.7652 2 20.5V4.5C2 4.23478 2.10536 3.98043 2.29289 3.79289C2.48043 3.60536 2.73478 3.5 3 3.5ZM20 7.738L12.072 14.838L4 7.716V19.5H20V7.738ZM4.511 5.5L12.061 12.162L19.502 5.5H4.511Z"
    />
  </svg>
</template>

<script>
export default {
  name: "MailIcon",
};
</script>
